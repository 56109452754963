/* src/components/Profile.css */
.profile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-left: 220px;
  padding-bottom: 50px;
  color: #efefec;
}

.profile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Visuals/profile_background.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.profile-container {
  background-color: rgba(28, 28, 28, 0.8);
  padding: 2rem;
  width: 800px;
  min-height: 100vh;
  margin-left: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-image {
  padding-left: 300px;
}

.profile-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid #a29c7c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-top: 1rem;
}

.profile-info h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.profile-info p {
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  max-width: 70%;
  padding-left: 120px;
}
