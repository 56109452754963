/* src/components/Contact.css */
.contact-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-left: 220px;
}

.contact-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Visuals/contact_background.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.contact-content {
  text-align: center;
  background-color: rgba(28, 28, 28, 0.8);
  padding: 2rem;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #efefec;
}

.contact-content h2 {
  padding-bottom: 1rem;
}

.contact-info p {
  margin-bottom: 1rem;
}

.contact-info i {
  margin-right: 0.5rem;
}

.contact-info a {
  color: inherit;
  text-decoration: none;
}

.contact-info a:hover {
  color: #0073b1;
}
