/* src/components/Awards.css */
.awards {
  position: relative;
  padding: 2rem;
  color: #efefec;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 265px;
}

.awards::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Visuals/awards_background.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.awards-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.awards ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.awards li {
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  background-color: rgba(28, 28, 28, 0.8);
  color: #efefec;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.award-symbol {
  font-size: 2rem;
  margin-right: 1rem;
}

.award-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
