.experience-timeline {
    position: relative;
    padding: 2rem;
    min-height: 100vh;
    color: #efefec;
    padding-left: 220px;
  }
  
  .experience-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Visuals/experiences_background.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    z-index: -1;
  }
  
  .timeline {
    position: relative;
    margin: 0 auto;
    padding: 2rem 0;
    width: 60%;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 5px;
    height: 100%;
    background-color: #7c6e4f;
  }
  
  .experience {
    position: relative;
    margin-bottom: 3rem;
  }
  
  .company {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .position {
    position: relative;
    padding: 1rem 1.5rem;
    width: 60%;
    background-color: rgba(28, 28, 28, 0.8);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .position:nth-child(odd) {
    margin-left: 57.5%;
  }
  
  .position:nth-child(even) {
    margin-left: -24%;
  }
  
  .position::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -65px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #7c6e4f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .position::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -65px;
    transform: translateY(-50%);
    width: 65px;
    height: 5px;
    background-color: #7c6e4f;
  }
  
  .position:nth-child(even)::before {
    left: auto;
    right: -65px;
  }
  
  .position:nth-child(even)::after {
    left: auto;
    right: -65px;
    width: 65px;
  }
  
  .title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .period {
    font-style: italic;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .description {
    font-size: 1rem;
    line-height: 1.5;
  }
  