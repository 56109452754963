/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #1c1c1c;
  padding: 1rem;
  width: 200px;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}

.navbar ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: auto;
}


.navbar li {
  text-align: center;
  margin: 1rem 0;
}

.navbar a {
  color: #efefec;
  text-decoration: none;
  font-weight: bold;
}

.navbar a:hover {
  color: #a29c7c;
}

.navbar img {
  max-width: 70%; /* Adjust the size as needed */
  height: auto; /* Keeps the aspect ratio of the image */
  margin: 0 auto; /* Centers the image */
  border-radius: 50%; /* Makes the image round */
  display: block; /* To apply margin auto correctly */
}