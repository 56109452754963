/* src/components/Projects.css */
.projects {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  color: #efefec;
  padding-left: 265px;
}

.projects::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Visuals/projects_background.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.projects-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.project-card {
  background-color: rgb(28, 28, 28, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.project-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.project-title {
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.project-description {
  text-align: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.modal-content {
  background-color: rgb(28, 28, 28, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 600px;
  max-height: 80vh; /* Set a maximum height for the modal content */
  margin-top: 50px; /* Replace top with margin-top */
  position: relative;
  overflow-y: auto; /* Enable vertical scrolling */
  color: #efefec;
  scrollbar-width: thin;
  scrollbar-color: rgba(15, 15, 15, 0.9) rgb(28, 28, 28);
}

.modal-content::-webkit-scrollbar {
  width: 6px;
  background-color: rgb(28, 28, 28);
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(15, 15, 15, 0.9);
}


.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}
